<script setup>
import { computed, onMounted, ref } from 'vue';
import DefaultButton from '@/components/Buttons/DefaultButton.vue';
import TextField from '@/components/Form/TextField.vue';
import InputLabel from '@/components/InputLabel.vue';
import RadioField from '@/components/Form/RadioField.vue';
import { useAuthStore } from '@/stores/auth';
import { storeToRefs } from 'pinia';
import { useUsersStore } from '@/stores/users';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';

const { t } = useI18n()
const emit = defineEmits(['submitted'])
const auth = useAuthStore()
const users = useUsersStore()
const { getCurrencies } = storeToRefs(auth)
const route = useRoute()
const credits = ref([])
const currencies = ref({})

const setCurrencies = () => {
    for (let c of getCurrencies.value) {
        currencies.value[c._id] = c.cod
    }
};

const form = ref({
    amount: 0,
    currency_id: '',
    type: 1,
})
const message = ref('')
const success = ref('')
const warning = ref('')
const loading = ref(false)
const updateCredit = async () => {
    loading.value = true
    form.value.amount = Number(form.value.amount)
    if (form.value.type == 1) {
        success.value = ''
        await users.addCredit(route.params.id, form.value).then(async res => {
            if (res.status == 204) {
                await fetchCredits()
                message.value = ''
                warning.value = ''
                success.value= 'addCredit.transaction.2'
                loading.value = false
                form.value.amount = 0
            }
        }).catch(error => {
            message.value = error.response.data.message,
            loading.value = false
            success.value = ''
            form.value.amount = 0
        })
    } else {
        await users.withdrawCredit(route.params.id, form.value).then(async res => {
            if (res.status == 204) {
                await fetchCredits()
                message.value = ''
                warning.value = ''
                success.value= 'addCredit.transaction.2'
                loading.value = false
                form.value.amount = 0
            }
            else if (res.data.ok ) {
                await fetchCredits()
                message.value = ''
                success.value= ''
                warning.value ='addCredit.transaction.1'
                loading.value = false
                form.value.amount = 0
            }
        }).catch(error => {
            message.value = error.response.data.message
            success.value = ''
            loading.value = false
            form.value.amount = 0
        })
    }
}

const fetchCredits = async () => {
    await users.fetchUserCredits(route.params.id).then(res => {
        if (res.data.ok) {
            credits.value = res.data.data
        }
    })
}

onMounted(async () => {
    setCurrencies()
    await fetchCredits()
})
</script>

<template>
    <div class="ubl-Container">
        <div>
            <h1 class="text-base font-bold">{{$t('partials.9')}}</h1>
        </div>

        <div class="py-2 px-4 bg-red-200/25 text-red-600 text-xs font-semibold mt-2" v-if="message">
            {{ t('addCredit.' + message) }}
        </div>
        <div class="py-2 px-4 bg-emerald-200/25 text-emerald-400 text-xs font-semibold" v-if="success">
            {{ t(success) }}
        </div>
        <div class="py-2 px-4 bg-orange-200/25 text-orange-400 text-xs font-semibold" v-if="warning">
            {{ t(warning) }}
        </div>
        <form @submit.prevent="updateCredit" class="mt-6 space-y-4">
            <div>
                <InputLabel :value="t('partials.28')" />
                <RadioField 
                    :options="{1: t('partials.31'), 0: t('partials.32')}"
                    v-model="form.type"
                    name="credit-type"
                    class="mt-1"
                />
            </div>
            <div>
                <InputLabel :value="t('partials.29')" />
                <RadioField 
                    :options="currencies"
                    v-model="form.currency_id"
                    name="user-currencies"
                    class="mt-1"
                />
            </div>
            <div>
                <InputLabel :value="t('partials.30')" />
                <TextField
                    v-model="form.amount"
                    type="number"
                    placeholder="Amount"
                    class="w-full mt-1"
                />
            </div>

            <div class="space-x-2">
                <DefaultButton :disabled="loading">
                    <span v-if="!loading">{{t('partials.10')}}</span>
                    <div v-if="loading" class="spinner mr-2" />
                </DefaultButton>
            </div>
        </form>

        <div class="space-y-4 mt-6">
            <h1 class="text-base font-bold">{{t('partials.11')}}</h1>

            <div v-for="credit in credits">
                <InputLabel :value="currencies[credit.cuid._id]" />
                <p class="text-base font-bold">{{ credit.am }}</p>
            </div>
        </div>
    </div>
</template>
<style>
.ubl-Container {
    background-color: #ebeded;
    @apply px-6 py-4
}
</style>
<style scoped>
.spinner,
.spinner:after {
    border-radius: 50%;
    width: 1.5em;
    height: 1.5em;
}

.spinner {
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 0.2em solid rgb(20, 19, 19);
    border-right: 0.2em solid rgb(42, 40, 40);
    border-bottom: 0.2em solid rgb(49, 46, 46);
    border-left: 0.2em solid transparent;
    transform: translateZ(0);
    animation: spinning 1s infinite linear;
}

@keyframes spinning {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>